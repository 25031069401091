import {
	createApp
} from 'vue'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//vue-router
import router from "./router"

//store
import store from "./store"


import App from './App.vue'

import VueEasyLightbox from "vue-easy-lightbox";


const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

app.use(VueEasyLightbox);

app.use(ElementPlus)
app.use(router)
app.use(store)
app.mount('#app')
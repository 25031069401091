<template>
	<loadingPage :visible="show"></loadingPage>
	<div class="login-background">
		<div class="login-container">
			<el-card class="login-card" shadow="hover">
				<div class="login-content">
					<div class="login-image">
						<img src="https://d2cdn.shopxo.vip/static/upload/images/common/2024/01/19/1705673306550126.png"
							alt="Login Image" />
					</div>
					<div class="login-form-container">
						<h2 class="login-title">登录</h2>
						<el-form :model="loginForm" :rules="rules" ref="loginFormRef" label-width="0">
							<el-form-item prop="username">
								<el-input v-model="loginForm.username" placeholder="用户名"></el-input>
							</el-form-item>
							<el-form-item prop="password">
								<el-input v-model="loginForm.password" type="password" placeholder="密码"
									show-password></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="handleLogin">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script setup>
	import {
		getSystemAll
	} from "@/https/api/systemByMy";
	import {
		ref,
		reactive,
		defineComponent,
		onMounted
	} from "vue";
	import {
		ElMessage
	} from "element-plus";
	import {
		login
	} from "@/https/api/user";
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from "vue-router";
	import loadingPage from "../components/loadingPage.vue";

	import {
		sysCommercial
	} from "@/https/api/sysCommercial"


	// 创建组件对象
	const MyComponent = defineComponent({
		components: {
			loadingPage,
		},
	});

	onMounted(() => {

	})

	const show = ref(false);

	const router = useRouter();
	const store = useStore();
	const loginForm = ref({
		username: "",
		password: "",
	});

	const loginFormRef = ref(null);

	const rules = reactive({
		username: [{
				required: true,
				message: "请输入用户名",
				trigger: "blur"
			},
			{
				min: 3,
				message: "用户名至少3个字符",
				trigger: "blur"
			}
		],
		password: [{
				required: true,
				message: "请输入密码",
				trigger: "blur"
			},
			{
				min: 3,
				message: "密码至少3个字符",
				trigger: "blur"
			}
		],
	});

	const authoritiesLogin = ref([]);

	const handleLogin = async () => {
		try {
			const valid = await loginFormRef.value.validate();
			if (valid) {
				try {
					const data = {
						userName: loginForm.value.username,
						password: loginForm.value.password,
					};
					show.value = true;
					const response = await login(data);
					if (response.msg === "登陆成功") {
						localStorage.setItem("token", response.data.token);
						const params = {
							userName: response.data.user.user.userName,
							authorities: response.data.user.authorities,
							id: response.data.user.user.id,
						};
						authoritiesLogin.value = response.data.user.authorities;

						store.commit("updateUser", params);
						show.value = false;

						const commRes = await sysCommercial(params.id);
						console.log(commRes);
						if (commRes.data) {
							localStorage.setItem("mid", commRes.data.id)
						} else {
							localStorage.setItem("mid", 0)
						}
						ElMessage.success("登录成功！");
						// await addGoodsRoute();
						router.push("/main");
					} else {
						show.value = false;
						ElMessage.error(response.data.message || "登录失败");
					}
				} catch (error) {
					ElMessage.error("登录出错，请稍后再试");
				}
			}
		} catch (error) {
			ElMessage.error("请填写正确的表单信息");
		}
	};

	// const generateMenuAside = (data) => {
	// 	return data.map(item => ({
	// 		path: item.url || '',
	// 		name: item.name,
	// 		lable: item.title,
	// 		sys: item.sys,
	// 		authority: item.authority,
	// 		children: item.systemList.length ? item.systemList.map(child => ({
	// 			path: child.url,
	// 			name: child.name,
	// 			lable: child.title,
	// 			sys: child.sys,
	// 			authority: child.authority,
	// 		})) : null
	// 	}));
	// };

	// const dataRoute = ref([]);

	// const showHas = (authority) => {
	// 	return authoritiesLogin.value.some(item => item.authority === authority);
	// };

	// // 动态添加路由的部分
	// const addGoodsRoute = async () => {
	// 	const res = await getSystemAll();
	// 	dataRoute.value = generateMenuAside(res.data);

	// 	const mainRoute = router.getRoutes().find(route => route.path === "/main");

	// 	if (mainRoute) {
	// 		dataRoute.value.forEach(rou => {
	// 			if (!rou.children && rou.sys && showHas(rou.authority)) {
	// 				mainRoute.children.push({
	// 					path: rou.path,
	// 					component: () => import(`@/views/${rou.sys}`) // 仅取文件名进行拼接
	// 				});
	// 			} else if (rou.children) {
	// 				const filteredChildren = rou.children.filter(chi => showHas(chi.authority));
	// 				if (filteredChildren.length > 0) {
	// 					filteredChildren.forEach(chi => {
	// 						mainRoute.children.push({
	// 							path: chi.path,
	// 							component: () => import(`@/views/${chi.sys}`) // 仅取文件名进行拼接
	// 						});
	// 					});
	// 				}
	// 			}
	// 		});

	// 		// 使用 router.addRoute 动态添加路由
	// 		router.addRoute(mainRoute);

	// 		// 确保导航到新添加的路由
	// 		if (mainRoute.children.length > 0) {
	// 			router.push(mainRoute.children[0].path);
	// 		}
	// 	}
	// };
</script>


<style scoped>
	.login-background {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		background: url("https://d2cdn.shopxo.vip/static/admin/default/images/login/1.png") no-repeat center center fixed;
		background-size: cover;
	}

	.login-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 500px;
	}

	.login-card {
		width: 900px;
		height: 500px;
		border-radius: 10px;
		overflow: hidden;
	}

	.login-content {
		display: flex;
	}

	.login-image img {
		width: 500px;
		height: 108%;
		object-fit: cover;
	}

	.login-form-container {
		flex: 1;
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.login-title {
		/* text-align: center; */
		margin-bottom: 30px;
		font-size: 24px;
		color: #333;
	}

	.el-form-item {
		margin-bottom: 20px;
	}

	.el-button {
		width: 100%;
	}
</style>
<template>
	<div>
		<el-menu class="el-menu-vertical-demo" :collapse="isCollapse" :background-color="asideColor"
			text-color="#ffffff" active-text-color="#2d4d6d">
			<el-menu-item v-for="item in noChild" :key="item.name" :index="item.name" @click="ToggleTag(item)">
				<el-icon v-if="item.icon">
					<component :is="item.icon" />
				</el-icon>
				<template #title>{{ item.lable }}</template>
			</el-menu-item>
			<el-sub-menu v-for="item in hasChild" :key="item.name" :index="item.name">
				<template #title>
					<el-icon v-if="item.icon">
						<component :is="item.icon" />
					</el-icon>
					<span>{{ item.lable }}</span>
				</template>

				<el-menu-item-group v-for="i in item.children" :key="i.name">
					<el-menu-item :index="i.name" @click="ToggleTag(i)">
						<el-icon v-if="i.icon">
							<component :is="i.icon" />
						</el-icon>
						{{ i.lable }}
					</el-menu-item>
				</el-menu-item-group>
			</el-sub-menu>
		</el-menu>
	</div>
</template>

<script setup>
	import {
		computed,
		onMounted,
		shallowRef
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from "vue-router";
	import * as Icons from "@element-plus/icons-vue"; // 动态引入所有图标

	import {
		getSystemAll
	} from "@/https/api/systemByMy";

	const store = useStore();
	const router = useRouter();

	const isCollapse = computed(() => store.state.system.isCollapse);
	const asideColor = computed(() => store.state.system.aside);
	const hea = computed(() => store.state.system.hea);

	// 使用 shallowRef 保持浅层响应式
	const menuAside = shallowRef({
		aside: [] // This will be dynamically populated
	});

	onMounted(() => {
		init();
	});

	const init = async () => {
		try {
			const res = await getSystemAll();
			if (res.code === 200 && res.data) {
				menuAside.value = {
					aside: generateMenuAside(res.data)
				};
				console.error('Failed to load menu data:', menuAside.value.aside);
			} else {
				console.error('Failed to load menu data:', res);
			}
		} catch (error) {
			console.error('Error loading data:', error);
		}
	};

	// const generateMenuAside = (data) => {
	// 	return data.map(item => ({
	// 		path: item.url || '',
	// 		name: item.name,
	// 		lable: item.title,
	// 		status: item.status,
	// 		icon: getIconComponent(item.icon),
	// 		authority: item.authority,
	// 		children: item.systemList.length ? item.systemList.map(child => ({
	// 			path: child.url,
	// 			name: child.name,
	// 			lable: child.title,
	// 			status: child.status,
	// 			icon: getIconComponent(child.icon),
	// 			authority: child.authority
	// 		})) : null
	// 	}));
	// };

	const generateMenuAside = (data) => {
		return data.map(item => ({
			path: item.url || '',
			name: item.name,
			lable: item.title,
			sys: item.sys,
			icon: getIconComponent(item.icon),
			status: item.status,
			showStatus: item.showStatus,
			authority: item.authority, // Assuming authority is provided
			children: item.systemList.length ? item.systemList.map(child => ({
				path: child.url,
				name: child.name,
				lable: child.title,
				sys: child.sys,
				icon: getIconComponent(child.icon),
				status: child.status,
				showStatus: child.showStatus,
				authority: child.authority // Assuming authority is provided
			})) : null
		}));
	};


	const authorities = JSON.parse(localStorage.getItem("vuex"))?.user?.authorities
	const showHas = (authority) => {
		return authorities.some(item => item.authority === authority);
	};
	const getIconComponent = (iconName) => {
		// 动态获取图标组件
		return Icons[iconName] || null;
	};
	const noChild = computed(() => menuAside.value.aside.filter((item) => !item.children && item.status == 1 && item
		.showStatus == 1 && showHas(
			item.authority)));

	const hasChild = computed(() => {
		console.log("-----------------------------------123------------");
		console.log(menuAside.value.aside);
		return menuAside.value.aside
			.filter(item => item.children != null)
			.map(item => {
				item.children = item.children.filter(child => child.status == 1 && child.showStatus == 1 &&
					showHas(child.authority));
				return item;
			})
			.filter(item => item.children.length > 0);
	});


	const ToggleTag = (item) => {
		if (item.path) {
			router.push(item.path);
		}
	};
</script>

<style scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 160px;
		height: 100vh;
	}

	.el-menu--collapse {
		height: 100vh;
	}

	/* 自定义 el-menu-item 和 el-sub-menu 在鼠标悬停时的样式 */
	::v-deep .el-menu-item:hover,
	::v-deep .el-sub-menu__title:hover {
		background-color: #ffffffef !important;
		/* 更改背景颜色 */
		color: #000000 !important;
		/* 更改文字颜色 */
		font-weight: 500 !important;
	}

	/* 确保二级菜单项也应用悬停样式 */
	::v-deep .el-menu-item-group .el-menu-item:hover {
		background-color: #ffffffef !important;
		color: #000000 !important;
		font-weight: 600 !important;
	}

	::v-deep .el-menu-item.is-active {
		color: v-bind(asideColor);
		background-color: v-bind(hea);
	}
</style>